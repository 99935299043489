import React, { useState, useEffect } from 'react';
import LoyaltyItem from '../components/loyalty/LoyaltyItem';
import LoyaltySpecialItem from '../components/loyalty/LoyaltySpecialItem';
import LoyaltyFinishModal from '../components/modal/LoyaltyFinishModal';
import '../css/Loyalty.css';
import { useDispatch, useSelector } from 'react-redux';
import { getLoyaltyProducts, getLoyaltySettings } from '../redux/actions/productActions';
import { Modal, Image, Container, Row, Col } from 'react-bootstrap';
import CurrencyFormat from '../components/other/CurrencyFormat';
import { globalStatistic } from '../redux/actions/otherActions';
import ScrollToTop from '../components/other/ScrollToTop';
import PageHelmet from '../components/helmet/PageHelmet';
import { getPageSeo } from '../redux/actions/pageActions';
import Captcha from '../components/other/Captcha';
import LoyaltyCounterBlock from '../components/loyalty/LoyaltyCounterBlock';
import { useTranslation } from 'react-i18next';
import MessageInfoBox from '../components/other/MessageInfoBox';
import { toast } from 'react-toastify';
import axios from 'axios';
import { axiosinstance } from '../configAxios';
// import FlipClock from "x-react-flipclock";
import Countdown, { zeroPad } from 'react-countdown';
import { get } from 'sortablejs';

const LoyaltyShopPage = () => {

    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(getLoyaltyProducts());
    // }, [dispatch]);
    const loyaltyProductsList = useSelector((state) => state.getLoyaltyProducts);
    const loyaltySettings = useSelector((state) => state.getLoyaltySettings);
    const specialSlshopList = loyaltyProductsList.specialSlshopList;
    // const specialSlshopListLng = specialSlshopList && specialSlshopList.length > 0 ? true : false;
    const specialSlshopListLng = specialSlshopList ? (specialSlshopList.length > 0 ? true : false) : false;
    const getUserInfo = useSelector((state) => state.userInfo.userInfo.companyAdditionalInfo);
    const getCurrentServerTime = useSelector((state) => state.getCurrentServerTime.items);
    const userInfo = useSelector((state) => state.userInfo);
    // 'isCancelButtonClicked' je stanje koje prati da li je dugme za odustajanje kliknuto.
    // 'null' je početna vrednost, što znači da dugme nije kliknuto.
    // 'setIsCancelButtonClicked' je funkcija koja se koristi za ažuriranje ovog stanja.    
    const [isCancelButtonClicked, setIsCancelButtonClicked] = useState(null);
    // 'shouldAnimat' je stanje koje prati da li treba da se pokrene animacija (pokrece se klikom na ljubicasto polje i klikom na dugme 'Odustani)
    // 'false' je početna vrednost, što znači da animacija neće biti pokrenuta odmah.
    // 'setShouldAnimate' je funkcija koja se koristi za ažuriranje ovog stanja.
    const [shouldAnimate, setShouldAnimate] = useState(false);


    // var objArr = {};
    const [show, setShow] = useState(false);
    const [counter, setCounter] = useState(0);
    const [activeShop, setActiveShop] = useState(0);

    const [finishCart, setFinishCart] = useState(false);
    const [emptyValues, setEmptyValues] = useState([]);
    const now = Date.now();
    const [pageSeoData, setPageSeoData] = useState({});
    const [clickedSpecialItem, setClickedSpecialItem] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [res, setRes] = useState();
    const [socketResponse, setSocketResponse] = useState([]);
    const [isClickedSpecialItem, setIsClickedSpecialItem] = useState(false);
    const [clickedItemData, setClickedItemData] = useState(null);
    const adLoyaltyStartDate = Date.parse(loyaltySettings.adLoyaltyStartDate)
    const adLoyaltyEndDate = Date.parse(loyaltySettings.adLoyaltyEndDate)
    const { t } = useTranslation('loyaltyShopPage');
    const [responseData, setResponseData] = useState(null);

    const [isSpecialBlockOpen, setIsSpecialBlockOpen] = useState(null);
    const isShopActive = loyaltySettings.isActive;
    // console.log(isShopActive == 0, "isShopActive");

    // New useEffect for WebSocket logic
    useEffect(() => {
        let socket;
        // if (loyaltyProductsList.isSpecialOpen !== 0) { // proveriti 20.06.2024.
        socket = socketFunction();
        // }
        return () => {
            if (socket) {
                socket.removeEventListener('message', handleMessageFromServer);
                if (socket.readyState === WebSocket.OPEN) {
                    socket.close();
                }
            }
        };
    }, [loyaltyProductsList]);

    const handleCancelButtonClick = (key) => {
        setIsCancelButtonClicked(key);
        setIsClickedSpecialItem(false);
        setTimeout(() => {
            setShouldAnimate(false);
        }, 2000);
        // setClickedSpecialItem(key);
        dispatch(getLoyaltyProducts(1));
    };


    const handleClose = () => {
        setShow(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setShow(true);
    }


    const renderButtonSubmitLoyalty = () => {
        if (getUserInfo.acCompanyLoyaltyStatus && (getUserInfo.acCompanyLoyaltyStatus.trim().toUpperCase() != 'CRVEN' && loyaltySettings.anCompanyTarget < loyaltySettings.anCompanyTargetAchieved && activeShop == 1 && (adLoyaltyStartDate <= now && adLoyaltyEndDate >= now))) {

            return (
                <div className={`${emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? "button-submit-ly-shop-disabled" : ""} button-submit-ly-shop`}>
                    <button type="submit" className="btn btn-success" disabled={emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? true : false} onClick={(e) => handleSubmit(e)} title={emptyValues.length == 0 || emptyValues.filter(i => i.value == 0).length > 0 ? t('validacija') : ""}><i className="fa fa-shopping-cart" aria-hidden="true"></i>&nbsp; {t('dugme')}</button>
                </div>
            );
        }
    };


    const setActiveShopFunction = (val) => {
        setActiveShop(val);
    };
    // console.log(specialSlshopList.length, "specialSlshopList");
    // const isSpecialBlockOpen = loyaltyProductsList.isSpecialOpen;//podatak koji prikazuje ili sklanja blok
    useEffect(() => {
        setIsSpecialBlockOpen(socketResponse?.isSpecialOpen);//???
    }, [socketResponse]);
    const handleItemClick = async (itemId, anLoyaltyTypeId, key) => {//ovde cu proslediti parametre koje velja ocekuje za kliknut item
        // console.log(itemId, "itemOd");
        setClickedSpecialItem(key);
        // handleSpecialItemClick(key);
        
        setShouldAnimate(true);
        setIsCancelButtonClicked(null);
        const attributes = {};
        attributes['anCompanyKey'] = userInfo.userInfo.company.anCompanyKey;
        attributes['anLoyaltyTypeId'] = anLoyaltyTypeId;
        attributes['anSpecialProductKey'] = itemId;

        // const responseData1 = {
        //     "_id": 1324868,
        //     "anCompanyKey": 1789,
        //     "anProductKey": 8499,
        //     "acProduct": "MON02957",
        //     "acProductName": "DAHUA 22 inča FHD Monitor LM22-A200",
        //     "acLoyaltyGroup": "Monitori i oprema             ",
        //     "anLoyaltyGroupOrdering": 4,
        //     "anPrice": 60.0,
        //     "anOldPrice": 60.62800000000001,
        //     "anQty": 1,
        //     "anQtyVirtual": 1,
        //     "anQtyAvailableForCompany": 1,
        //     "isPriceVisible": 1,
        //     "isSpecialForCompany": 0,
        //     "isSaleConnected": 0,
        //     "acConditionType": "UslovljenaProdaja",
        //     "anProductId": 6404,
        //     "anLoyaltyTypeId": 1
        // };

        // console.log(responseData1, "responseData");


        const configRes = axiosinstance(attributes, '8224', 'postCompanyLoyalityShopSpecialLog');

        axios(configRes)
            .then(function (response) {
                // console.log(response.data.response.setLoyaltyShopProduct, "responsee123");
                if (response.data.response.isOk === 1) {
                    // console.log(response.data);
                    setResponseData(response.data.response.setLoyaltyShopProduct);
                    setIsClickedSpecialItem(true);
                    // setIsSpecialBlockOpen(response.data.response.isSpecialOpen);

                } else {
                    toast(<MessageInfoBox message={response.data.response.acMsg} type="0" />, {
                        className: 'red-background',
                    });
                    setIsClickedSpecialItem(false);
                }

                // if (response.data.isOk == 1) {
                //     // setIsSpecialBlockOpen(response.data.response.isSpecialOpen);
                // }
                // if (response.data.isOk == 1) {
                //     window.location.reload();
                // }
                // // toast(<MessageInfoBox message={response.data.response.acMsg} type={response.data.response.isOk} />, {
                //     className: response.data.response.isOk == 1 ? window.location.reload() : '',


            });
    };

    //ako je isSpecialOpen!==0 onda se okida socket kako bismo znali da li je neki product u medjuvremenu prodat ili nije, ako jeste onda otvaram taj product i prikayujem info o tome da je kupljeno i ko je kupio


    const handleMessageFromServer = (event) => {
        // console.log("uleti u handle mess");
        const message = event.data;
        // console.log(message, 'message');
        // 
        try {
            const parsedMessage = JSON.parse(message);
            console.log(parsedMessage, 'websocket parsedMessage');
            setSocketResponse(parsedMessage);
        } catch (error) {
            console.error('error:', error);
        }
    };
    // console.log(loyaltySettings, "loyaltySettings");

    const socketFunction = () => {
        const data = {
            anCompanyKey: userInfo.userInfo.company.anCompanyKey,
            anLoyaltyType: loyaltySettings.anLoyaltyTypeId
        };
        const url = `wss://newb2b.ewe.rs:8775/specialProductLoyality/${data.anCompanyKey},${data.anLoyaltyType}`;
        const socket = new WebSocket(url);

        socket.addEventListener('open', function (event) {
            // console.log('WebSocket veza otvorena.');

            // socket.send('Pozdrav serveru!');
        });

        socket.addEventListener('message', handleMessageFromServer);

        socket.addEventListener('close', function (event) {
            // console.log('WebSocket veza zatvorena.');
        });

        socket.addEventListener('error', function (error) {
            console.error('WebSocket greška:', error);
        });
        return socket;
    };

    useEffect(() => {
        dispatch(getPageSeo(766)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        });

        dispatch(getLoyaltyProducts(1));
        dispatch(getLoyaltySettings(1));
        dispatch(globalStatistic(766, 'PAGE'));

    }, [dispatch, userInfo.userInfo.company.anCompanyKey]);


    // console.log(isSpecialBlockOpen, "isSpecialBlockOpen");


    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={pageSeoData ? `${pageSeoData.acSeoTitle}` : "Ewe Comp"}
                    description={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    keywords={pageSeoData ? `${pageSeoData.acSeoKeywords}` : ""}
                    ogDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                    ogTitle={pageSeoData ? `${pageSeoData.acSeoTitle}` : ""}
                    ogUrl={pageSeoData ? `https://ewe.rs/${pageSeoData.acSeoUrl}` : ""}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    twitterDescription={pageSeoData ? `${pageSeoData.acSeoDescription}` : ""}
                />
            }
            {loyaltySettings.length != 0 && (
                <div className="container-content container-main-content target-content loyalty-shop-content-container">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <LoyaltyCounterBlock loyaltySettings={loyaltySettings} setActiveShopFunction={setActiveShopFunction} activeShop={activeShop} />
                            </div>

                            {loyaltySettings.anCompanyTarget > 0 ? (
                                <>
                                    <div className="col-sm-12">
                                        <div className="loyaty-holder-info-user-box">
                                            <div className="loyalty-info-box-item">
                                                <h1>{t('naslov1')}</h1>
                                                {loyaltySettings.anLoyaltyTargetPercentRemain > 0 ? (
                                                    <>
                                                        <div className="total-sum-percent-loyalty">{loyaltySettings.anLoyaltyTargetPercentRemain}%</div>
                                                        <div className="scala-holder-loyalty">
                                                            {loyaltySettings.anLoyaltyTargetPercentRemain == 0 ? (
                                                                <>
                                                                    <div className="scala-holder-loyatly-left" style={{ width: `calc(0% + 17px)` }}></div>
                                                                    <div className="scala-holder-loyatly-right"></div>
                                                                    <div className="scala-holder-loyalty-circle" style={{ left: `0%` }}></div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="scala-holder-loyatly-left" style={{ width: `calc(${100 - loyaltySettings.anLoyaltyTargetPercentRemain}% + 17px)` }}></div>
                                                                    <div className="scala-holder-loyatly-right"></div>
                                                                    <div className="scala-holder-loyalty-circle" style={{ left: `${100 - loyaltySettings.anLoyaltyTargetPercentRemain}%` }}></div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="scala-holder-numers">
                                                            <div className="scala-holder-number-item scala-holder-number-item">0</div>
                                                            <div className="scala-holder-number-item scala-holder-number-item-25">25</div>
                                                            <div className="scala-holder-number-item scala-holder-number-item-50">50</div>
                                                            <div className="scala-holder-number-item scala-holder-number-item-75">75</div>
                                                            <div className="scala-holder-number-item scala-holder-number-item-100">100</div>
                                                        </div>
                                                        <span className="shop-target-description-loyalty">{t('paragraf1')}</span>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i className="fas fa-check"></i>
                                                        <span className="shop-target-description-loyalty shop-target-description-loyalty-second">{t('paragraf2')}</span>
                                                    </>
                                                )}
                                            </div>
                                            <div className="loyalty-info-box-item">
                                                <p>{t('paragraf3')}</p>
                                                <div className="total-sum-percent-loyalty">{loyaltySettings.anLoyaltyTargetPacePrediction}%</div>
                                                <span className="shop-target-description-loyalty shop-target-description-loyalty-second">{t('paragraf4')}</span>
                                            </div>
                                            <div className="loyalty-info-box-item">
                                                <div className="bottom-divider">
                                                    <div className="loyalty-upper-desc">{t('paragraf5')}</div>
                                                    <div className="stat-target red"><CurrencyFormat num={loyaltySettings.anCompanyTarget} /> <small>EUR</small></div>
                                                    <div className="loyalty-upper-desc">{t('paragraf6')}</div>
                                                    <div className="stat-target green"><CurrencyFormat num={loyaltySettings.anCompanyTargetAchieved} /> <small>EUR</small></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {loyaltyProductsList.isSaleConnected == 1 && (
                                        <div className="col-lg-12">
                                            <div className="info-icons-action">
                                                <span>{t('paragraf7')}</span> <img alt={t('bedz')} className="smallBadge" src="/images/loyalty/badges-povezana-prodaja.png" /> <span>{t('paragraf8')}</span> <img alt={t('bedz')} className="smallBadge" src="/images/loyalty/badges-povezana-prodaja-artikal.png" /> <span>{t('paragraf9')}</span>
                                            </div>
                                        </div>
                                    )}
                                    {getUserInfo.acCompanyLoyaltyStatus.trim().toUpperCase() == 'CRVEN' &&
                                        <div className="col-sm-12">
                                            <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                                {t('paragraf10')}
                                            </div>
                                        </div>
                                    }


                                    {isShopActive == 0 && specialSlshopListLng ? (
                                        <div className="col-sm-12 loyalty-special">
                                            <div className='loyalty-special-product-text-boxes'>
                                                <div style={{ maxWidth: '1016px' }}>
                                                    <div className='loyalty-special-product-text-img'>
                                                        <img src="/images/loyalty/5-stars-loyalty.png" className="" alt="Loyalty Stars" style={{ width: '20%', height: 'auto' }} />
                                                        <p>Ostvarite pravo na specijalnu ponudu kupovinom bar jednog loyalty artikla! Svi skriveni artikli dostupni su po povlašćenim cenama, ali možete izabrati samo jedan. Nakon kupovine kliknite na skriveno polje i iskoristite ovu posebnu priliku</p>
                                                    </div>
                                                    <div className='justify-content-center loyalty-special-product-holder'>
                                                        {specialSlshopList && specialSlshopList.map((item, key) => {
                                                            return (
                                                                <div className="special-flip-holder">
                                                                    <div
                                                                        key={key}
                                                                        className='specialShopDiv'
                                                                        style={{
                                                                            cursor: clickedSpecialItem !== null ? 'pointer' : 'not-allowed',
                                                                            pointerEvents: clickedSpecialItem !== null ? 'none' : 'none'
                                                                        }}
                                                                    >
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <div className='' id={key} style={{ width: "100%" }}>
                                                                            <div className="product-special-img" style={{ width: '250px', height: '250px' }}>
                                                                                <div>?</div>
                                                                                <img src="/images/loyalty/logo-loyalty8.png" alt="" className="img-fluid img-loyalty-product" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : specialSlshopListLng && (isSpecialBlockOpen == 1 || (isClickedSpecialItem && isSpecialBlockOpen == 2)) ? (
                                        <div className="col-sm-12 loyalty-special">
                                            <div className='loyalty-special-product-text-boxes'>
                                                <div style={{ maxWidth: '1016px' }}>
                                                    <div className='loyalty-special-product-text-img'>
                                                        <img src="/images/loyalty/5-stars-loyalty.png" className="" alt="Loyalty Stars" style={{ width: '20%', height: 'auto' }} />
                                                        <p>Čestitamo! Ostvarili ste pravo na specijalnu ponudu! Svi skriveni artikli dostupni su po povlašćenim cenama, ali možete izabrati samo jedan. Kliknite na željeni artikal i iskoristite ovu posebnu priliku.</p>                                                        </div>
                                                    <div className='justify-content-center loyalty-special-product-holder'>
                                                        {socketResponse && socketResponse.specialSlshopList && socketResponse.specialSlshopList.map((item, key) => {
                                                            const isItemOpen = (item.acBuyer !== null || clickedSpecialItem === key);
                                                            // console.log(clickedSpecialItem === key, "test?");
                                                            // isItemOpen će biti postavljen na true (belo polje) samo ako je ispunjen jedan od sledećih uslova:
                                                            // 1. item.acBuyer nije null ili
                                                            // 2. clickedSpecialItem je jednak key, što znači da je korisnik kliknuo na ljubicasto polje.
                                                            // console.log(isItemOpen, 'isItemOpen');
                                                            // console.log(clickedSpecialItem, 'clickedSpecialItem');
                                                            // console.log(key, 'keyyy');
                                                            // console.log(item._id, 'items idd');
                                                            // var acBuyer = item.acBuyer;
                                                            // console.log(isClickedSpecialItem == false, "test truefals");

                                                            // console.log(responseData1, "resdtata");
                                                            // console.log(item, "item sockettt")

                                                            return (
                                                                <div className="special-flip-holder" key={key}>
                                                                    {isItemOpen && (
                                                                        <LoyaltySpecialItem
                                                                            handleCancelButtonClick={handleCancelButtonClick}
                                                                            clickedSpecialItem={clickedSpecialItem}
                                                                            setShouldAnimate={setShouldAnimate}
                                                                            finishCart={finishCart}
                                                                            keyItem={item}
                                                                            shopIsActive={activeShop}
                                                                            getUserInfo={getUserInfo}
                                                                            loyaltySettings={loyaltySettings}
                                                                            isClickedSpecialItem={isClickedSpecialItem}
                                                                            item={isClickedSpecialItem === true ? (responseData ?  responseData  : null) : { item }}
                                                                            setEmptyValues={setEmptyValues}
                                                                            emptyValues={emptyValues}
                                                                            type={1}
                                                                            start={loyaltySettings.adLoyaltyStartDate}
                                                                            end={loyaltySettings.adLoyaltyEndDate}
                                                                            isSpecialOpen={loyaltyProductsList.isSpecialOpen}
                                                                            setFinishCartFunction={setFinishCart}
                                                                            itemKey={key}
                                                                        />
                                                                    )}
                                                                    <div
                                                                        // className={`specialShopDiv ${clickedSpecialItem === key ? 'flip' : ''}`}
                                                                        // className={`specialShopDiv ${clickedSpecialItem === key && shouldAnimate ? 'flip' : ''}`}
                                                                        className={`specialShopDiv ${isItemOpen || clickedSpecialItem === key ? 'flip' + (isCancelButtonClicked === key ? 'flip-reverse' : '') : ''}`} style={{
                                                                            cursor: clickedSpecialItem !== null ? 'not-allowed' : 'pointer',
                                                                            pointerEvents: clickedSpecialItem !== null ? 'none' : 'auto',
                                                                        }}
                                                                        onClick={() => handleItemClick(item._id, loyaltySettings.anLoyaltyTypeId, key)}
                                                                    >
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <div className='' id={key} style={{ width: "100%" }}>
                                                                            <div className="product-special-img" style={{ width: '250px', height: '250px' }}>
                                                                                <div>?</div>
                                                                                <img src="/images/loyalty/logo-loyalty8.png" alt="" className="img-fluid img-loyalty-product" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    ) : specialSlshopListLng && isSpecialBlockOpen == 0 ? (
                                        <div className="col-sm-12 loyalty-special">
                                            <div className='loyalty-special-product-text-boxes'>
                                                <div style={{ maxWidth: '1016px' }}>
                                                    <div className='loyalty-special-product-text-img'>
                                                        <img src="/images/loyalty/5-stars-loyalty.png" className="" alt="Loyalty Stars" style={{ width: '20%', height: 'auto' }} />
                                                        <p>Ostvarite pravo na specijalnu ponudu kupovinom bar jednog loyalty artikla! Svi skriveni artikli dostupni su po povlašćenim cenama, ali možete izabrati samo jedan. Nakon kupovine kliknite na skriveno polje i iskoristite ovu posebnu priliku</p>
                                                    </div>
                                                    <div className='justify-content-center loyalty-special-product-holder'>
                                                        {socketResponse && socketResponse.specialSlshopList && socketResponse.specialSlshopList.map((item, key) => {
                                                            return (
                                                                <div className="special-flip-holder">
                                                                    <div
                                                                        key={key}
                                                                        className='specialShopDiv'
                                                                        style={{
                                                                            cursor: clickedSpecialItem !== null ? 'pointer' : 'not-allowed',
                                                                            pointerEvents: clickedSpecialItem !== null ? 'none' : 'none'
                                                                        }}
                                                                    >
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <div className='' id={key} style={{ width: "100%" }}>
                                                                            <div className="product-special-img" style={{ width: '250px', height: '250px' }}>
                                                                                <div>?</div>
                                                                                <img src="/images/loyalty/logo-loyalty8.png" alt="" className="img-fluid img-loyalty-product" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : specialSlshopListLng && (isSpecialBlockOpen == 2 && !isClickedSpecialItem) ? (
                                        <div className="col-sm-12 loyalty-special">
                                            <div className='loyalty-special-product-text-boxes'>
                                                <div style={{ maxWidth: '1016px' }}>
                                                    <div className='loyalty-special-product-text-img'>
                                                        <img src="/images/loyalty/5-stars-loyalty.png" className="" alt="Loyalty Stars" style={{ width: '20%', height: 'auto' }} />
                                                        <p>Specijalna ponuda je zatvorena. Iskoristili ste mogućnost kupovine ili su svi artikli rasprodati.</p>
                                                    </div>
                                                    <div className='justify-content-center loyalty-special-product-holder'>
                                                        {socketResponse && socketResponse.specialSlshopList && socketResponse.specialSlshopList.map((item, key) => {
                                                            const isItemOpen = item.acBuyer !== null;
                                                            // console.log(isItemOpen, 'isItemOpen');
                                                            // console.log(clickedSpecialItem, 'clickedSpecialItem');
                                                            // console.log(isCancelButtonClicked, 'isCancelButtonClickeddd');
                                                            // console.log(key, 'keyyy');
                                                            return (
                                                                <div className="special-flip-holder" key={key}>
                                                                    {isItemOpen && (
                                                                        <LoyaltySpecialItem
                                                                            handleCancelButtonClick={handleCancelButtonClick}
                                                                            clickedSpecialItem={clickedSpecialItem}
                                                                            setShouldAnimate={setShouldAnimate}
                                                                            finishCart={finishCart}
                                                                            keyItem={item}
                                                                            shopIsActive={activeShop}
                                                                            getUserInfo={getUserInfo}
                                                                            loyaltySettings={loyaltySettings}
                                                                            item={item}
                                                                            setEmptyValues={setEmptyValues}
                                                                            emptyValues={emptyValues}
                                                                            type={1}
                                                                            start={loyaltySettings.adLoyaltyStartDate}
                                                                            end={loyaltySettings.adLoyaltyEndDate}
                                                                            isSpecialOpen={loyaltyProductsList.isSpecialOpen}
                                                                            setFinishCartFunction={setFinishCart}
                                                                            itemKey={key}
                                                                        />
                                                                    )}
                                                                    <div
                                                                        key={key}
                                                                        className={`specialShopDiv ${isItemOpen ? 'flip' : ''}`}
                                                                        style={{
                                                                            cursor: 'not-allowed',
                                                                            pointerEvents: 'none'
                                                                        }}
                                                                    // onClick={() => handleItemClick(key, item.anLoyaltyTypeId)}
                                                                    >
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <span></span>
                                                                        <div className='' id={key} style={{ width: "100%" }}>
                                                                            <div className="product-special-img" style={{ width: '250px', height: '250px' }}>
                                                                                <div>?</div>
                                                                                <img src="/images/loyalty/logo-loyalty8.png" alt="" className="img-fluid img-loyalty-product" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null
                                    }

                                    <div className="col-sm-12">
                                        <div className="loyalty-table-content">
                                            <form id="form-loyalty-shop">
                                                <table className="table table-loyalty">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-align-center" style={{ width: '100px' }}>{t('kolona1')}</th>
                                                            <th>{t('kolona2')}</th>
                                                            <th>{t('kolona3')}</th>
                                                            <th>{t('kolona4')}</th>
                                                            <th className="text-align-right">{t('kolona5')}</th>
                                                            <th className="text-align-right">{t('kolona6')}</th>
                                                            <th className="text-align-right">{t('kolona7')}</th>
                                                            <th className="text-align-right">{t('kolona8')}</th>
                                                            <th>{t('kolona9')}</th>
                                                            <th style={{ width: '100px' }}>{t('kolona10')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loyaltyProductsList.companyLoyalityShop && loyaltyProductsList.companyLoyalityShop.map((item, key) => (
                                                            item.loyaltyShopItemList.length !== 0 ? (
                                                                <>
                                                                    <tr className="custom-name-title" key={key}>
                                                                        <td colSpan={10}>
                                                                            {item.acLoyaltyGroup === '' ? (
                                                                                <div className="loyalty-header-info-products">{t('paragraf11')}</div>
                                                                            ) : (
                                                                                <div className="loyalty-header-info-products">{item.acLoyaltyGroup}</div>
                                                                            )}
                                                                        </td>
                                                                    </tr>

                                                                    {item.loyaltyShopItemList.map((item2, key2) => (
                                                                        <LoyaltyItem finishCart={finishCart} key={key2} keyItem={key2} shopIsActive={activeShop} getUserInfo={getUserInfo} loyaltySettings={loyaltySettings} item={item2} setEmptyValues={setEmptyValues} emptyValues={emptyValues} />
                                                                    ))}
                                                                </>
                                                            ) : null
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </form>
                                            {renderButtonSubmitLoyalty()}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="col-sm-12">
                                    <div className="alert alert-danger w-100 alert-info-loyalty" role="alert">
                                        {t('paragraf12')}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div >
            )}
            <ScrollToTop />
            <Modal
                show={show}
                onHide={handleClose}
                keyboard={false}
                dialogClassName='modal-xl'
                aria-labelledby=""
                centered
            >
                <div className="close-action-modal" variant="secondary" onClick={handleClose}>
                    <i className="far fa-times-circle"></i>
                </div>
                <Modal.Header>
                    <h4>{t('naslov2')}</h4>
                </Modal.Header>
                <Modal.Body>
                    <Captcha setFinishCartFunction={setFinishCart} finishCart={finishCart} handleClose={handleClose} setEmptyValues={setEmptyValues} type={1} start={loyaltySettings.adLoyaltyStartDate} end={loyaltySettings.adLoyaltyEndDate} socketResponse={socketResponse} />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LoyaltyShopPage;