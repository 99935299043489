const items = {
    sr: {
        'errorLoginFail': 'Korisnicko ime ili lozinka nisu ispravni.',
        'menuSuccess': 'Uspesno ucitan meni.',
        'cartOverStockError': 'Trenutno nema količine artikla na stanju.',
        'cartMinStockError': 'Nije moguće dodati količinu manju od 1.',
        'cartSuccess': 'Uspešno dodat artikal u korpu.',
        'cartRemoveSuccess': 'Uspešno obrisana korpa.',
        'cartItemRemoveSuccess': 'Uspešno ste uklonili proizvod iz korpe.',
        'cartActivateSuccess': 'Promenjena aktivna korpa.',
        'cartListRemoveSuccess': 'Uspešno uklonjeni artikli iz korpe.',
        'cartNoItemsError': 'Korpa je prazna.',
        'wishListAddSuccess': 'Uspešno dodat artikal u listu želja.',
        'removeWishSuccess': 'Uspešno uklonjen artikal iz liste želja.',
        'wishListAddError': 'Nije moguće dodati artikal u listu želja.',
        'companyLoyalityShopSuccess': 'Uspešna kupovina.',
        'stockLoyalityShopError': 'Nema dovoljno zaliha na stanju za odabrani artikal.',
        'noActiveLoyalityError': 'Loyalty shop nije aktivan još uvek.',
        'successRegistrationMail': 'Prodajni tim će Vas kontaktirati i odgovoriti na Vaš zahtev. Unapred se radujemo zajedničkoj saradnji.',
        'errorReqiuredFormInputs': 'Morate popuniti sva obavezna polja u formi.',
        'errorRequiredCaptcha': 'Kod sa slike nije ispravan.',
        'compareErrorMaxNumber': 'Maksimalan broj uredjaja za poredjivanje je 4.',
        'cartFinishSuccess': 'Uspešno obavljena kupovina.',
        'ReclamationCreateSuccess': 'Uspešno ste zakazali preuzimanje reklamacija sa vaše adrese.',
        'notAllowedExtensions': 'Dozvoljene ekstenzije dokumenta: .png, .jpg, .gif, .jpeg, .pdf, .JPEG!',
        'notAllowedProfileExtensions': 'Dozvoljene ekstenzije dokumenta: .png, .jpg, .gif, .jpeg, .JPEG!',
        'notAllowedFileSize': 'Maksimalna veličina dokumenta je 200KB!',
        'uploadDocumentSuccess': 'Usepšno ste snimili dokument.',
        'uploadDocumentsSuccess': 'Usepšno ste snimili dokumenta.',
        'deleteDocumentSuccess': 'Usepšno ste obrisali dokument.',
        'deleteDocumentError': 'Nastala je greška. Dokument nije obrisan!',
        'endUserDeliverySuccess': 'Uspešno ste uneli informacije za isporuku po fakturi',
        'vocherAddSuccess': 'Uspešno dodati vaučeri.',
        'vocherAddError': 'Broj odabranih vaučera se ne podudara sa ukupnom količinom artikla.',
        'errorFinishCartInputsRequired': 'Morate popuniti obavezna polja.',
        'successContactMail': 'Vaša poruka je poslata.',
        'errorReqiuredPasswordInputs': 'Molimo unesite ispravne podatke.',
        'relatedSaleQtyNotAllowed': 'Količina artikla sa popustom ne može preći količinu artikala na osnovu kojih dobijate popust.',
        'relatedSaleQtyReduction': 'Količina artikla sa popustom je takođe smanjena.',
        'cartExsistsError': 'Aktivna korpa u koju pokušavate da dodate artikal više ne postoji.',
        'voucherAddRemoveSuccess': 'Uspešno ste dodali vaučer.',
        'voucherRemoveSuccess': 'Uspešno ste obrisali vaučer.',
        'errorCreateNewCartEmptyName': 'Unesite naziv korpe.',
        'cartErrorRemoveDefault': 'Nije dozvoljeno brisanje osnovne korpe.',
        'errorNoFunctionWithThatName': 'Došlo je do problema, molimo Vas da kontaktirate admina.',
        'globalError': 'Došlo je do greške, molimo Vas da kontaktirate admina.',
        'cartRemoveItemSuccess': 'Uspešno ste uklonili proizvod iz korpe.',
        'cartSubSuccess': 'Uspešno ste umanjili količinu.',
        'cartIncSuccess': 'Uspešno ste uvećali količinu.',
        'surveyQuestionAnsweredSuccess': 'Uspešno ste odgovorili na pitanje.',
        'surveyQuestionAnsweredError': 'Već ste odgovorili na pitanje.',
        'surveyAlreadyFinishError': 'Već ste popunili anketu.',
        'surveyExpiredError': 'Anketa je istekla.',
        'surveyCompleted': 'Hvala vam na vremenu izdvojenom za našu anketu. Proverite svoju email poštu!',
        'promoCodeCheckDoesntExistsError': 'Promo kod koji ste uneli nije ispravan ili ne ispunjava uslov za korišćenje.',
        'promoCodeCheckSuccess': 'Uspešno ste aktivirali promo kod.',
        'resetPassword': 'Proverite svoju email poštu!',
        'failToGenerateTokkenError': 'Nastala je greška! Molimo Vas da kontaktirate admina.',
        'emailNotFoundError': 'Email adresa nije ispravna!',
        'brLkNotFoundError': 'Molimo unesite broj lične karte!',
        'phoneNotFoundError': 'Molimo unesite broj telefona!',
        'surnameNotFoundError': 'Molimo unesite svoje prezime!',
        'errorContactAdmin': 'Nastala je greška! Molimo Vas da kontaktirate admina.',
        'errorUserNotFound': 'Korisnik ne postoji.',
        'badTokkenError': 'Token nije ispravan.',
        'successResetPassword': 'Uspešno ste promenili lozinku.',
        'auctionBidLowBidError': 'Cenu koju pokušavate da ponudite je manja od minimalne.',
        'auctionBidSuccess': 'Uspešno ste licitirali.',
        'successUserUpdate': 'Uspešno ste promenili podatke.',
        'successCompanyEmailChange': 'Uspešno ste promenili email adresu.',
        'successCompanyDataChange': 'Uspešno ste promenili podatke.',
        'successUserSaved': 'Uspešno ste dodali novog korisnika.',
        'companyTargetError': 'Vaš target nije ostvaren, ne možete učestvovati u loyalty kupovini.',
        'subscribeExistsError': 'Već pratite ovaj proizvod.',
        'unsubscribeSuccess': 'Uspešno ste se odjavili.',
        'subscribeSuccess': 'Uspešno ste se prijavili.',
        'cartAddSuccess': 'Uspešno ste dodali atrikle u korpu.',
        'cartCloneSuccess': 'Uspešno ste klonirali korpu.',
        'postB2bNewsletterAllreadySingupError': 'Već ste prijavljeni na naš newsletter.',
        'cartVoucherMissmatchError': 'Količina artikla mora biti jednaka broju iskorišćenih vaučera.',
        'errorMissingOneOrMoreFields': 'Molimo unesite sve podatke',
        'errorMissingOneOrMoreFields2': 'Molimo popunite ispravno sva polja',
        'postInsertCoinUserSuccess': 'Uspešno ste se registrovali za učestvovanje u akciji',
        'userExistsError': 'Korisnik Vaše kompanije je već registrovan',
        'errorEmailExist': 'Korisnik sa ovom email adresom već postoji',
        'successfullyDeleted': 'Uspešno obrisano',
        'unsuccessfullyDeleted': 'Brisanje nije uspelo',
        'voucherAddRemoveCartPromoCodeQtyError': 'Količina artikla mora biti jednaka broju iskorišćenih vaučera.',
        'successUserPasswordChange': 'Uspešno promenjena lozinka',
        'postB2bNewsletterSingupSuccess': 'Uspešno ste se prijavili na naš newsletter!',
        'departmentNotMainError': 'Korisnik Vaše kompanije je već registrovan',
        'loyaltyLimitQty': 'Ne možete kupovati više od maksimalno dostupne količine.',
        'loyaltyNoZeroQty': 'Molimo unesite pozitivnu količinu.',
        'errorSessionTokken': 'Došlo je do greške, molimo Vas da kontaktirate admina.',
        'somethingWentWrong': 'Nešto je pošlo po zlu.',
        'validationErrorNumberExist': 'Nije moguće uneti broj u polju za ime.',
        'validationErrorNumberExistSurname': 'Nije moguće uneti broj u polju za prezime.',
        'validationErrorLetterExistPhone': 'Nije moguće slova u polju za broj telefona.',
        'validationErrorLetterExistBrLk': 'Nije moguće slova u polju za broj lične karte.',
        'happeningRegistrationSuccess': 'Uspešno ste se prijavili za Tehnovacije 2023.',
        'email already registrated': 'Korisnik sa ovom email adresom je već registrovan.',
        'radioButtonBus': 'Molimo odaberite da li cete koristiti Ewe autobus.',
        'radioButtonParking': 'Molimo odaberite da li Vam je potreban parking u Beogradu.',
        'radioButtonEquipment': 'Molimo odaberite da li Vam je potrebna oprema za skijanje.',
        'radioButtonSkiing': 'Molimo odaberite da li vam je potreban SKI Pass.',
        'dateOfBirthError': 'Molimo unesite datum rođenja.',
        'noProfileImageSelectedError': 'Molimo dodajte sliku za Ski Pass.',
        'companyNameError': 'Naziv kompanije sadrži nedozvoljene znakove.',
        'nameError': 'Ime i Prezime sadrži nedozvoljene znakove.',
        'endUserDeliveryFileSuccess': 'Uspešno sačuvani podaci.',
        'notEditableDeliveryAttachment': 'Prilog naloga nije moguće menjati!',
        // 'radioButtonReferal': 'Molimo odaberite da li Vam je potreban razgovor sa komercijalistom.',
        'LoyProductSold': 'Proizvod je u međuvremenu prodat.',
        'loylatySpecialOk' : 'Neko iz Vaše kompanije je već pregledao proizvod.',
    },
    mk: {
        "errorLoginFail": "Корисничкото име или лозинката не се точни.",
        "menuSuccess": "Мени-то е успешно вчитано.",
        "cartOverStockError": "Во моментов нема доволно количество на артиклот на залиха.",
        "cartMinStockError": "Не е можно да додадете количество помало од 1.",
        "cartSuccess": "Артиклот е успешно додаден во кошницата.",
        "cartRemoveSuccess": "Кошницата е успешно избришана.",
        "cartItemRemoveSuccess": "Артиклот е успешно отстранет од кошницата.",
        "cartActivateSuccess": "Активната кошница е променета.",
        "cartListRemoveSuccess": "Артиклите се успешно отстранети од кошницата.",
        "cartNoItemsError": "Кошницата е празна.",
        "wishListAddSuccess": "Артиклот е успешно додаден во листата на желби.",
        "removeWishSuccess": "Артиклот е успешно отстранет од листата на желби.",
        "wishListAddError": "Не е можно да додадете артикл во листата на желби.",
        "companyLoyalityShopSuccess": "Успешна купувачка.",
        "stockLoyalityShopError": "Нема доволно залиха на избраниот артикл.",
        "noActiveLoyalityError": "Loyalty shop сè уште не е активен.",
        "successRegistrationMail": "Продажниот тим ќе ве контактира и ќе одговори на вашето барање. Веќе се радуваме на заедничката соработка.",
        "errorReqiuredFormInputs": "Мора да ги пополните сите задолжителни полиња во формата.",
        "errorRequiredCaptcha": "Кодот од сликата не е точен.",
        "compareErrorMaxNumber": "Максималниот број на уреди за споредба е 4.",
        "cartFinishSuccess": "Купувачката е успешно завршена.",
        "ReclamationCreateSuccess": "Успешно ја закажавте преземањето на рекламациите од вашата адреса.",
        "notAllowedExtensions": "Дозволени екстензии на документи: .png, .jpg, .gif, .jpeg, .pdf, .JPEG!",
        "notAllowedProfileExtensions": "Дозволени екстензии на документи: .png, .jpg, .gif, .jpeg, .JPEG!",
        "notAllowedFileSize": "Максималната големина на документот е 200KB!",
        "uploadDocumentSuccess": "Документот е успешно зачуван.",
        "uploadDocumentsSuccess": "Документите се успешно зачувани.",
        "deleteDocumentSuccess": "Документот е успешно избришан.",
        "deleteDocumentError": "Се случи грешка. Документот не е избришан!",
        "endUserDeliverySuccess": "Успешно ги внесовте информациите за испорака по фактурата.",
        "vocherAddSuccess": "Ваучерите се успешно додадени.",
        "vocherAddError": "Бројот на избраните ваучери не се совпаѓа со вкупното количество на артиклот.",
        "errorFinishCartInputsRequired": "Мора да ги пополните задолжителните полиња.",
        "successContactMail": "Вашата порака е испратена.",
        "errorReqiuredPasswordInputs": "Ве молиме внесете точни податоци.",
        "relatedSaleQtyNotAllowed": "Количината на артиклот со попуст не може да ја надмине количината на артиклите врз основа на кои добивате попуст.",
        "relatedSaleQtyReduction": "Количината на артиклот со попуст е исто така намалена.",
        "cartExsistsError": "Активната кошница во која се обидувате да додадете артикл повеќе не постои.",
        "voucherAddRemoveSuccess": "Успешно го додадовте ваучерот.",
        "voucherRemoveSuccess": "Успешно го избришавте ваучерот.",
        "errorCreateNewCartEmptyName": "Внесете име на кошницата.",
        "cartErrorRemoveDefault": "Не е дозволено бришење на основната кошница.",
        "errorNoFunctionWithThatName": "Се случи проблем, ве молиме контактирајте го администраторот.",
        "globalError": "Се случи грешка, ве молиме контактирајте го администраторот.",
        "cartRemoveItemSuccess": "Артиклот е успешно отстранет од кошницата.",
        "cartSubSuccess": "Количината е успешно намалена.",
        "cartIncSuccess": "Количината е успешно зголемена.",
        "surveyQuestionAnsweredSuccess": "Успешно одговоривте на прашањето.",
        "surveyQuestionAnsweredError": "Веќе сте одговориле на прашањето.",
        "surveyAlreadyFinishError": "Веќе сте ја пополниле анкетата.",
        "surveyExpiredError": "Анкетата е истечена.",
        "surveyCompleted": "Ви благодариме за вашето време посветено на нашата анкета. Проверете ја вашата електронска пошта!",
        "promoCodeCheckDoesntExistsError": "Промо кодот кој го внесовте не е точен или не ги исполнува условите за користење.",
        "promoCodeCheckSuccess": "Промо кодот е успешно активиран.",
        "resetPassword": "Проверете ја вашата електронска пошта!",
        "failToGenerateTokkenError": "Се случи грешка! Ве молиме контактирајте го администраторот.",
        "emailNotFoundError": "Електронската пошта не е точна!",
        "brLkNotFoundError": "Ве молиме внесете го бројот на личната карта!",
        "phoneNotFoundError": "Ве молиме внесете го телефонскиот број!",
        "surnameNotFoundError": "Ве молиме внесете го вашето презиме!",
        "errorContactAdmin": "Се случи грешка! Ве молиме контактирајте го администраторот.",
        "errorUserNotFound": "Корисникот не постои.",
        "badTokkenError": "Токенот не е точен.",
        "successResetPassword": "Лозинката е успешно променета.",
        "auctionBidLowBidError": "Цената која се обидувате да ја понудите е помала од минималната.",
        "auctionBidSuccess": "Успешно сте лицитирале.",
        "successUserUpdate": "Податоците се успешно променети.",
        "successCompanyEmailChange": "Електронската пошта е успешно променета.",
        "successCompanyDataChange": "Податоците се успешно променети.",
        "successUserSaved": "Новиот корисник е успешно додаден.",
        "companyTargetError": "Вашата цел не е остварена, не можете да учествувате во loyalty купувачката.",
        "subscribeExistsError": "Веќе го следите овој производ.",
        "unsubscribeSuccess": "Успешно се отпишавте.",
        "subscribeSuccess": "Успешно се претплативте.",
        "cartAddSuccess": "Артиклите се успешно додадени во кошницата.",
        "cartCloneSuccess": "Кошницата е успешно клонирана.",
        "postB2bNewsletterAllreadySingupError": "Веќе сте пријавени на нашиот newsletter.",
        "cartVoucherMissmatchError": "Количината на артиклот мора да биде еднаква на бројот на искористените ваучери.",
        "errorMissingOneOrMoreFields": "Ве молиме внесете ги сите податоци.",
        "errorMissingOneOrMoreFields2": "Ве молиме правилно пополнете ги сите полиња.",
        "postInsertCoinUserSuccess": "Успешно се регистриравте за учество во акцијата.",
        "userExistsError": "Корисникот од вашата компанија веќе е регистриран.",
        "errorEmailExist": "Корисник со оваа електронска пошта веќе постои.",
        "successfullyDeleted": "Успешно избришано.",
        "unsuccessfullyDeleted": "Бришењето не успеа.",
        "voucherAddRemoveCartPromoCodeQtyError": "Количината на артиклот мора да биде еднаква на бројот на искористените ваучери.",
        "successUserPasswordChange": "Лозинката е успешно променета.",
        "postB2bNewsletterSingupSuccess": "Успешно се пријавивте на нашиот newsletter!",
        "departmentNotMainError": "Корисникот од вашата компанија веќе е регистриран.",
        "loyaltyLimitQty": "Не можете да купувате повеќе од максималното достапно количество.",
        "loyaltyNoZeroQty": "Ве молиме внесете позитивно количество.",
        "errorSessionTokken": "Се случи грешка, ве молиме контактирајте го администраторот.",
        "somethingWentWrong": "Нешто појде наопаку.",
        "validationErrorNumberExist": "Не е можно да внесете број во полето за име.",
        "validationErrorNumberExistSurname": "Не е можно да внесете број во полето за презиме.",
        "validationErrorLetterExistPhone": "Не е можно да внесете букви во полето за телефонски број.",
        "validationErrorLetterExistBrLk": "Не е можно да внесете букви во полето за број на лична карта.",
        "happeningRegistrationSuccess": "Успешно се пријавивте за Tehnovacije 2023.",
        "email already registrated": "Корисник со оваа електронска пошта веќе е регистриран.",
        "radioButtonBus": "Ве молиме одберете дали ќе го користите Ewe автобусот.",
        "radioButtonParking": "Ве молиме одберете дали ви е потребен паркинг во Белград.",
        "radioButtonEquipment": "Ве молиме одберете дали ви е потребна опрема за скијање.",
        "radioButtonSkiing": "Ве молиме одберете дали ви е потребен SKI Pass.",
        "dateOfBirthError": "Ве молиме внесете го датумот на раѓање.",
        "noProfileImageSelectedError": "Ве молиме додадете слика за Ski Pass.",
        "companyNameError": "Името на компанијата содржи недозволени знаци.",
        "nameError": "Името и презимето содржат недозволени знаци.",
        "endUserDeliveryFileSuccess": "Податоците се успешно зачувани.",
        "notEditableDeliveryAttachment": "Прилогот на налогот не може да се менува!",
        "loylatySpecialOk": "Некој од вашата компанија веќе го прегледал производот."
    },
    en: {
        'errorLoginFail': 'Username or password is incorrect.',
        'menuSuccess': 'Menu loaded successfully.',
        'cartOverStockError': 'Currently, the item is out of stock.',
        'cartMinStockError': 'It is not possible to add less than 1 item.',
        'cartSuccess': 'Item successfully added to the cart.',
        'cartRemoveSuccess': 'Cart successfully deleted.',
        'cartItemRemoveSuccess': 'You have successfully removed the product from the cart.',
        'cartActivateSuccess': 'Active cart changed.',
        'cartListRemoveSuccess': 'Items successfully removed from the cart.',
        'cartNoItemsError': 'The cart is empty.',
        'wishListAddSuccess': 'Item successfully added to the wishlist.',
        'removeWishSuccess': 'Item successfully removed from the wishlist.',
        'wishListAddError': 'Unable to add item to the wishlist.',
        'companyLoyalityShopSuccess': 'Purchase successful.',
        'stockLoyalityShopError': 'Insufficient stock available for the selected item.',
        'noActiveLoyalityError': 'Loyalty shop is not yet active.',
        'successRegistrationMail': 'Our sales team will contact you and respond to your request. We look forward to our future cooperation.',
        'errorReqiuredFormInputs': 'You must fill in all the required fields in the form.',
        'errorRequiredCaptcha': 'The code from the image is incorrect.',
        'compareErrorMaxNumber': 'The maximum number of devices for comparison is 4.',
        'cartFinishSuccess': 'Purchase completed successfully.',
        'ReclamationCreateSuccess': 'You have successfully scheduled the pickup of returns from your address.',
        'notAllowedExtensions': 'Allowed document extensions: .png, .jpg, .gif, .jpeg, .pdf, .JPEG!',
        'notAllowedProfileExtensions': 'Allowed document extensions: .png, .jpg, .gif, .jpeg, .JPEG!',
        'notAllowedFileSize': 'The maximum document size is 200KB!',
        'uploadDocumentSuccess': 'Document successfully saved.',
        'uploadDocumentsSuccess': 'Documents successfully saved.',
        'deleteDocumentSuccess': 'Document successfully deleted.',
        'deleteDocumentError': 'An error occurred. The document was not deleted!',
        'endUserDeliverySuccess': 'You have successfully entered delivery information for the invoice.',
        'vocherAddSuccess': 'Vouchers successfully added.',
        'vocherAddError': 'The number of selected vouchers does not match the total quantity of the item.',
        'errorFinishCartInputsRequired': 'You must fill in the required fields.',
        'successContactMail': 'Your message has been sent.',
        'errorReqiuredPasswordInputs': 'Please enter valid information.',
        'relatedSaleQtyNotAllowed': 'The quantity of discounted items cannot exceed the quantity of items that qualify for the discount.',
        'relatedSaleQtyReduction': 'The quantity of discounted items has also been reduced.',
        'cartExsistsError': 'The active cart you are trying to add an item to no longer exists.',
        'voucherAddRemoveSuccess': 'Voucher successfully added.',
        'voucherRemoveSuccess': 'Voucher successfully removed.',
        'errorCreateNewCartEmptyName': 'Please enter a name for the cart.',
        'cartErrorRemoveDefault': 'Deleting the default cart is not allowed.',
        'errorNoFunctionWithThatName': 'There was a problem, please contact the admin.',
        'globalError': 'An error occurred, please contact the admin.',
        'cartRemoveItemSuccess': 'You have successfully removed the product from the cart.',
        'cartSubSuccess': 'Quantity successfully decreased.',
        'cartIncSuccess': 'Quantity successfully increased.',
        'surveyQuestionAnsweredSuccess': 'You have successfully answered the question.',
        'surveyQuestionAnsweredError': 'You have already answered the question.',
        'surveyAlreadyFinishError': 'You have already completed the survey.',
        'surveyExpiredError': 'The survey has expired.',
        'surveyCompleted': 'Thank you for taking the time to complete our survey. Check your email!',
        'promoCodeCheckDoesntExistsError': 'The promo code you entered is invalid or does not meet the usage criteria.',
        'promoCodeCheckSuccess': 'Promo code successfully activated.',
        'resetPassword': 'Check your email!',
        'failToGenerateTokkenError': 'An error occurred! Please contact the admin.',
        'emailNotFoundError': 'The email address is invalid!',
        'brLkNotFoundError': 'Please enter your ID card number!',
        'phoneNotFoundError': 'Please enter your phone number!',
        'surnameNotFoundError': 'Please enter your surname!',
        'errorContactAdmin': 'An error occurred! Please contact the admin.',
        'errorUserNotFound': 'User does not exist.',
        'badTokkenError': 'The token is invalid.',
        'successResetPassword': 'Password successfully changed.',
        'auctionBidLowBidError': 'The price you are trying to bid is lower than the minimum.',
        'auctionBidSuccess': 'You have successfully placed a bid.',
        'successUserUpdate': 'Information successfully updated.',
        'successCompanyEmailChange': 'Email address successfully changed.',
        'successCompanyDataChange': 'Data successfully changed.',
        'successUserSaved': 'New user successfully added.',
        'companyTargetError': 'Your target has not been achieved, you cannot participate in loyalty purchases.',
        'subscribeExistsError': 'You are already following this product.',
        'unsubscribeSuccess': 'You have successfully unsubscribed.',
        'subscribeSuccess': 'You have successfully subscribed.',
        'cartAddSuccess': 'Items successfully added to the cart.',
        'cartCloneSuccess': 'Cart successfully cloned.',
        'postB2bNewsletterAllreadySingupError': 'You are already signed up for our newsletter.',
        'cartVoucherMissmatchError': 'The quantity of items must be equal to the number of vouchers used.',
        'errorMissingOneOrMoreFields': 'Please enter all the information.',
        'errorMissingOneOrMoreFields2': 'Please correctly fill in all the fields.',
        'postInsertCoinUserSuccess': 'You have successfully registered to participate in the action.',
        'userExistsError': 'A user from your company is already registered.',
        'errorEmailExist': 'A user with this email address already exists.',
        'successfullyDeleted': 'Successfully deleted.',
        'unsuccessfullyDeleted': 'Deletion failed.',
        'voucherAddRemoveCartPromoCodeQtyError': 'The quantity of items must be equal to the number of vouchers used.',
        'successUserPasswordChange': 'Password successfully changed.',
        'postB2bNewsletterSingupSuccess': 'You have successfully signed up for our newsletter!',
        'departmentNotMainError': 'A user from your company is already registered.',
        'loyaltyLimitQty': 'You cannot purchase more than the maximum available quantity.',
        'loyaltyNoZeroQty': 'Please enter a positive quantity.',
        'errorSessionTokken': 'An error occurred, please contact the admin.',
        'somethingWentWrong': 'Something went wrong.',
        'validationErrorNumberExist': 'It is not possible to enter a number in the name field.',
        'validationErrorNumberExistSurname': 'It is not possible to enter a number in the surname field.',
        'validationErrorLetterExistPhone': 'It is not possible to enter letters in the phone number field.',
        'validationErrorLetterExistBrLk': 'It is not possible to enter letters in the ID card number field.',
        'happeningRegistrationSuccess': 'You have successfully registered for Technovations 2023.',
        'email already registrated': 'A user with this email address is already registered.',
        'radioButtonBus': 'Please select whether you will use the Ewe bus.',
        'radioButtonParking': 'Please select whether you need parking in Belgrade.',
        'radioButtonEquipment': 'Please select whether you need ski equipment.',
        'radioButtonSkiing': 'Please select whether you need a SKI Pass.',
        'dateOfBirthError': 'Please enter your date of birth.',
        'noProfileImageSelectedError': 'Please add a picture for the Ski Pass.',
        'companyNameError': 'The company name contains invalid characters.',
        'nameError': 'The name and surname contain invalid characters.',
        'endUserDeliveryFileSuccess': 'Data successfully saved.',
        'notEditableDeliveryAttachment': 'Order attachment cannot be edited!',
        'loylatySpecialOk': 'Someone from your company has already reviewed the product.'
    }
};

export default items;